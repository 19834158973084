import React from 'react';
import { useRouter } from 'next/router';

import Header from 'components/header';
import List from 'components/list';
import Footer from 'components/footer';

import { queryList } from 'lib/query';

function HomePage({ data }) {
  const { query, push } = useRouter();
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const page = query.page || 1;

  const nextPage = () => {
    push({
      pathname,
      query: {
        page: +page + 1,
      },
    });
  };

  const prevPage = () => {
    const prevPageNumber = +page - 1;
    if (prevPageNumber > 1) {
      push({
        pathname,
        query: {
          page: prevPageNumber,
        },
      });
    } else {
      push(pathname);
    }
  };

  return (
    <>
      <Header />
      <div className="main container p-20 bg-white">
        {page === 1 ? (
          <>
            <h1 className="h2 text-center">
              Welcome to Leaked
              <span className="text-theme-color">Nude Celebs</span>
            </h1>
            <p className="text-center h5 mb-4">
              Your best place for OnlyFans & more adult leaks. Fresh leaks every day!
            </p>
          </>
        ) : null}
        <List page={page} data={data} />
        <nav>
          <ul className="pagination">
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={prevPage} type="button">
                Previous
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" onClick={nextPage} type="button">
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <Footer />
    </>
  );
}

export async function getServerSideProps({ query }) {
  try {
    const { page } = query;
    let pageNum = 1;
    if (page && !Array.isArray(page) && typeof +page === 'number') {
      pageNum = parseInt(page, 10);
    }

    const data = await queryList(pageNum);

    return {
      props: {
        data,
      },
    };
  } catch (e) {
    console.log(e);
    return {
      notFound: true,
    };
  }
}

export default HomePage;
